'use client';

import { useSession } from 'next-auth/react';

import { useAuthStore } from '@/stores/userStore';

import {
  AUTHENTICATED_SESSION_STATUS,
  LOADING_SESSION_STATUS,
} from '@/constants/core';

import Footer from '@/components/footer/Footer';
import RegistrationPopup from '@/components/modals/RegistrationPopup';

import NavBarAuthenticated from '@/components/navbar/NavBarAuthenticated';
import NavigationBar from '@/components/navbar/NavigationBar';
import PublicNavBar from '@/components/navbar/PublicNavbar';

import SideNavbar from '@/components/navbar/SideBar';

export default function NavigationStructure({
  children,
}: {
  children: React.ReactNode;
}) {
  const {
    account,
  } = useAuthStore();
  const { status } = useSession();

  if (status === LOADING_SESSION_STATUS) {
    return null;
  }

  return (
    <>
      <NavigationBar />
      <div className="grid gap-0">
        <div className="grid col-start-1 col-end-2 row-start-2 row-end-4 h-dvh max-lg:hidden">
          <SideNavbar />
        </div>
        <div className="grid col-start-1 col-end-4 row-start-1 row-end-2 w-full fixed z-10">
          {status === AUTHENTICATED_SESSION_STATUS
            && account?.fullProfile !== undefined && (
            <RegistrationPopup
              isOpen={account?.fullProfile}
              account={account}
            />
          )}
          {status === AUTHENTICATED_SESSION_STATUS ? (
            <NavBarAuthenticated />
          ) : (
            <PublicNavBar />
          )}
        </div>
        <div className="grid col-start-2 col-end-3 row-start-2 row-end-3 w-full">
          {children}
        </div>
        <div className="grid col-start-2 col-end-3 row-start-3 row-end-3 w-full">
          <Footer />
        </div>
      </div>
    </>
  );
}
