import React from 'react';

import Image from 'next/image';

import { Separator } from '@/components/ui/separator';

import bac from '@/public/img/footer/bac.png';
import banpais from '@/public/img/footer/banpais.png';
import bitcoin from '@/public/img/footer/bitcoin.png';
import fenafu from '@/public/img/footer/Sponsors/fenafu.png';
import h from '@/public/img/footer/Sponsors/h.png';
import marathon from '@/public/img/footer/Sponsors/marathon.png';
import Motagua from '@/public/img/footer/Sponsors/Motagua.png';
import RealDE from '@/public/img/footer/Sponsors/RealDE.png';

import tigo from '@/public/img/footer/tigo.png';
import visamaster from '@/public/img/footer/visamaster.png';
// import wallet from '@/public/img/footer/wallet.png';

const Sponsors = () => {
  return (
    <>
      <div className="flex flex-row  flex-wrap justify-evenly items-center my-8  gap-8 ">
        <Image src={bac} alt="Bac Credomatic" width={100} height={34.5} className="lg:scale-150 object-fill max-lg:max-w-[80px]" />
        <Image src={banpais} alt="Banpais" width={100} height={37} className="lg:scale-150 object-fill max-lg:max-w-[80px]" />
        <Image src={tigo} alt="Tigo" width={100} height={30} className="lg:scale-150 object-fill max-lg:max-w-[80px]" />
        <Image src={bitcoin} alt="Criptos" width={125} height={40} className="lg:scale-105 object-contain max-lg:max-w-[70px]" />
        <Image src={visamaster} alt="Visa Master" width={110} height={60} className="lg:scale-150 object-fill max-lg:max-w-[80px]" />
      </div>
      <Separator className="bg-sivarbet-border my-4" />
      <h4 className="font-bold">Patrocinadores oficiales:</h4>
      <div className="flex flex-row flex-wrap justify-evenly items-center my-8">
        <Image src={Motagua} alt="Motagua" width={50} height={34.5} className="lg:scale-150 object-fill max-md:max-w-[120px]" />
        <Image src={fenafu} alt="Fenafu" width={50} height={40} className="lg:scale-150 object-fill max-md:max-w-[80px]" />
        <Image src={h} alt="Honduras" width={40} height={37} className="lg:scale-150 object-fill max-md:max-w-[80px]" />
        <Image src={marathon} alt="marathon" width={50} height={30} className="lg:scale-150 object-fill max-lg:max-w-[80px]" />
        <Image src={RealDE} alt="RealDE" width={50} height={40} className="lg:scale-150 object-contain max-lg:max-w-[100px]" />
      </div>
    </>
  );
};

export default Sponsors;
