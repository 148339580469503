/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

import Image from 'next/image';

import PromoSvg from '@/features/svgDesing/PromoSvg';
import { userUIStore } from '@/stores/userUIStore';

import {
  Tabs, TabsContent, TabsList, TabsTrigger,
} from '@/components/ui/tabs';

// import Nico from '@/public/img/footer/Nico-Master.png';
import payphone from '@/public/img/footer/visamastercard.webp';

// import Directa24 from './Directa24';
// import NicoPay from './Nico';
import PayPhone from './PayPhone';

const CreditAndDebitCard = () => {
  // const [iframe, setIframe] = useState(false);
  const { setOpenDeposit } = userUIStore();
  const [isHover, setIsHover] = useState('');
  const [activeTab, setActiveTab] = useState('default');

  return (
    <div className="p-3">
      <Tabs defaultValue="default" onValueChange={(value) => { return setActiveTab(value); }}>
        {(isHover === 'payphone' && activeTab === 'default') || activeTab === 'payphone' ? (
          <div className={`flex ${activeTab === 'payphone' ? 'w-full' : 'w-1/2'} justify-between my-1 px-2 text-sivarbet-text text-sm`}>
            <span className="text-xs md:text-sm font-medium">Min: L $25</span>
            <span className="text-xs md:text-sm font-medium">Max: L $12.500</span>
          </div>
        ) : null}

        {/* {(isHover === 'nico' && activeTab === 'default') || activeTab === 'nico' ? (
          <div className="flex justify-end w-full">
            <div className={`flex ${activeTab === 'nico' ? 'w-full' : 'w-1/2'} justify-between my-1 px-2 text-sivarbet-text text-sm`}>
              <span className="text-xs md:text-sm font-medium">Min: L $200</span>
              <span className="text-xs md:text-sm font-medium">Max: L $60.000</span>
            </div>
          </div>
        ) : null} */}
        <TabsList className="grid grid-cols-2 gap-3 w-full h-full bg-sivarbet-secondary-bg py-2 mt-2">

          <TabsTrigger
            value="payphone"
            className="flex justify-center items-center text-sivarbet-secondary font-medium data-[state=active]:text-sivarbet-text hover:bg-sivarbet-primary-hover hover:text-sivarbet-text data-[state=active]:bg-sivarbet-primary h-full"
            onClick={() => { return setOpenDeposit(true); }}
            onMouseEnter={() => { return setIsHover('payphone'); }}
            onMouseLeave={() => { return setIsHover(''); }}
          >
            <div className="flex flex-col items-center justify-center relative">
              <PromoSvg height={40} width={80} className="absolute top-[-15%] md:top-[-17%] left-[-40%] md:left-[-53%]  rotate-[-45deg] shadow-sm" />
              <Image src={payphone} alt="PayPhone" width={120} height={120} />
            </div>
          </TabsTrigger>
          {/* <TabsTrigger
            value="nico"
            className="flex justify-center items-center hover:bg-sivarbet-primary-hover data-[state=active]:bg-sivarbet-primary h-full"
            onClick={() => { return setOpenDeposit(true); }}
            onMouseEnter={() => { return setIsHover('nico'); }}
            onMouseLeave={() => { return setIsHover(''); }}
          >
            <Image
              src={Nico}
              alt="Nico"
              width={120}
              height={120}
            />
          </TabsTrigger> */}
          {/* <TabsTrigger value="directa" className="flex justify-center items-center hover:bg-sivarbet-primary-hover data-[state=active]:bg-sivarbet-primary h-full">
            <Image src={directa} alt="directa" width={70} height={70} className="rounded-full" />
          </TabsTrigger> */}
        </TabsList>

        {/* <TabsContent value="nico">
          <NicoPay />
        </TabsContent> */}

        <TabsContent value="payphone">
          <PayPhone />
        </TabsContent>

        <TabsContent value="default">
          <div className="flex flex-col items-center justify-center h-full">
            <span className="text-sivarbet-text text-center mt-10 text-xl">
              Selecciona la pasarela de pago de tu preferencia
            </span>
          </div>
        </TabsContent>
        {/* <TabsContent value="directa">
          <Directa24 />
        </TabsContent> */}
      </Tabs>
    </div>
  );
};

export default CreditAndDebitCard;
