/* eslint-disable react/require-default-props */

'use client';

import React, { useState, useEffect } from 'react';

import SlotCounter from 'react-slot-counter';

import { core } from '@/localization';

import { useAuthStore } from '@/stores/userStore';

import { moneyFormat } from '@/utils/core';

import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { Separator } from '../ui/separator';
import {
  Sheet, SheetContent, SheetPortal, SheetTitle, SheetTrigger,
} from '../ui/sheet';

type Props = {
  data: any,
};

const SelectBalanceSheet = ({
  data,
}: Props) => {
  const { balance } = useAuthStore();
  const [prevAmount, setPrevAmount] = useState<number>(0);

  useEffect(() => {
    if (data.length > 0) setPrevAmount(data[0]?.amount ?? 0);
  }, [data]);

  const renderPopover = (value: {
    type: string;
    amount: number;
    description: string;
    color?: string;
  }) => {
    let popoverBodyContent;

    if (value.description === 'Saldo disponible') {
      popoverBodyContent = (
        <div className="text-sm text-wrap">
          <div>
            Saldo que has liberado.
            Este saldo es el que puedes retirar. Para solicitar un retiro navega a tu perfil y dale click a la opcion de retirar
            {' '}
          </div>
        </div>
      );
    } else if (value.description === 'Saldo deposito') {
      popoverBodyContent = (
        <div className="text-sm text-wrap">
          Saldo que has depositado.
          nota que el saldo depositado no se puede retirar hasta no liberalo.
          para liberar saldo lo debes de jugar al menos una vez. Para mas informacion acede la academia de Honubet.
          <br />
          <b>academia.hondubet.com</b>
        </div>
      );
    } else if (value.description === 'Bonos casino') {
      popoverBodyContent = (
        <div className="text-sm text-wrap">
          <div>
            freespins en casino. Los freespins estan limitados a ciertos juegos y tienen un tiempo de expiracion. Para mas informacion acede la academia de Hondubet.
            <br />
            <b>academia.hondubet.com</b>
          </div>
        </div>
      );
    } else {
      popoverBodyContent = (
        <div className="text-sm text-wrap">
          <div>Bonos Deportivos sin liberar. este saldo se puede usar en apuestas deportivas </div>
        </div>
      );
    }

    return (
      <div className="bg-sivarbet-secondary-bg p-4 rounded-md shadow-lg">
        <h3 className="font-bold">{value.description}</h3>
        {popoverBodyContent}
      </div>
    );
  };

  if (data.length === 0) return null;

  return (
    <Sheet>
      <SheetTrigger data-testid="select-button" className="py-3 px-2 flex items-center rounded bg-sivarbet-secondary-bg min-w-[100px] max-md:!py-2 min-md:w-[100px]">
        <span className="text-sivarbet-secondary icon icon-coins_line text-l max-md:text-xs" />
        <span className="text-sivarbet-secondary pl-2 flex items-center">
          <SlotCounter
            startValue={moneyFormat(prevAmount)}
            value={moneyFormat(balance[0]?.amount.toFixed(2) ?? '0')}
            startValueOnce
            duration={2}
            animateUnchanged
            autoAnimationStart
          />
        </span>
      </SheetTrigger>
      <SheetContent side="bottom" className="bg-sivarbet-background border-sivarbet-border text-sivarbet-text w-full rounded-t rounded-xl shadow-2xl shadow-gray backdrop-opacity-30 px-0">
        <SheetTitle className="text-lg flex flex-row items-center gap-1 pb-4 text-sivarbet-text px-3">
          <span className="fill-sivarbet-secondary icon icon-coins_line" />
          Saldos
        </SheetTitle>
        <h3 className="text-sivarbet-secondary font-bold text-xl pb-5 flex justify-between px-3">
          {`${core.totalBalance}`}
          <span className="font-normal">
            {`${moneyFormat(balance[0]?.amount.toFixed(2) ?? '0')}`}
          </span>
        </h3>
        <div className="text-nowrap">
          {data.filter((_:any, index:number) => { return index > 0; }).map((item:any) => {
            return (
              <React.Fragment key={item.description}>
                <div
                  className="px-"
                >
                  <div className="flex justify-between items-center gap-x-28 w-full text-lg my-4">
                    <span className="px-3">{moneyFormat(item.amount)}</span>
                    <div className="flex justify-end items-center px-3">
                      <span>{item.description}</span>
                      <Popover>
                        <PopoverTrigger>
                          <span className="fill-white icon icon-info end-full pl-3" />
                        </PopoverTrigger>
                        <PopoverContent className="w-min bg-sivarbet-secondary-bg text-sivarbet-primary border-sivarbet-border">
                          {renderPopover(item)}
                        </PopoverContent>
                      </Popover>
                      <SheetPortal>
                        {renderPopover(item)}
                      </SheetPortal>
                    </div>
                  </div>
                </div>
                <Separator className="bg-sivarbet-border" />
              </React.Fragment>
            );
          })}
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default SelectBalanceSheet;
