'use client';

import { useEffect, useState } from 'react';

import Link from 'next/link';

import { useSession } from 'next-auth/react';
import { CiCircleMinus, CiCirclePlus } from 'react-icons/ci';

import { useAuthStore } from '@/stores/userStore';

import { decrypt } from '@/utils/core';

import { PAYPHONE_CONVERSION_RATE } from '@/constants/core';

import LoadingSpinner from '@/components/common/LoadingSpinner';

import { Button } from '@/components/ui/button';
import IconGift from '@/components/ui/Icons/IconGift';
import { Input } from '@/components/ui/input';

import { Separator } from '@/components/ui/separator';

import { useToast } from '@/components/ui/use-toast';

import { getPayPhoneCallback } from '../services';

const CreditCardOption = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState('');
  const [isOtherValue, setIsOtherValue] = useState(false);
  const [showPromoInput, setShowPromoInput] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [isVerify, setIsVerify] = useState(false);
  const [error, setError] = useState('');
  const { toast } = useToast();
  const { account } = useAuthStore();
  const { data: session } = useSession();
  const minDeposit = 25;
  const maxDeposit = 12500;

  useEffect(() => {
    if (account.kycLevel === 1 || account.kycLevel === 2) {
      setIsVerify(true);
    } else {
      toast({
        title: 'Verificación requerida',
        description: 'Necesitas completar la verificación de identidad para depositar dinero',
        variant: 'destructive',
      });
    }
  }, [account, toast]);

  const handleAmountClick = (amount: number) => {
    setError('');
    setSelectedAmount(amount.toFixed(2)); // Ensure the value has two decimal places when selected
    setIsOtherValue(false);
  };

  const handleOtherValueClick = () => {
    setSelectedAmount('');
    setIsOtherValue(true);
  };

  const togglePromoCode = () => {
    setShowPromoInput(!showPromoInput);
  };

  const handleDeposit = async (e: { preventDefault: () => void }) => {
    try {
      if (!session || !session.user) {
        toast({
          title: 'Sesion no iniciada',
          description: 'Inicia sesion e intenta nuevamente',
          variant: 'destructive',
        });
        return;
      }
      const userId = await decrypt(session.user?.data, session.user?.tag);

      if (userId) {
        e.preventDefault();
        const timestamp = Date.now();
        const uniqueTransactionId = `${userId}-${timestamp}`;

        const conversionRate = Number(PAYPHONE_CONVERSION_RATE); // 1 USD = 0.040 HNL
        const amountInUSD = parseFloat(selectedAmount) * conversionRate;

        const json = {
          amount: Math.round(amountInUSD * 100),
          amountWithoutTax: Math.round(amountInUSD * 100),
          currency: 'USD',
          clientTransactionId: uniqueTransactionId,
          email: account.email,
          responseUrl: `${process.env.NEXT_PUBLIC_RESPONSE_URL}/?deposit=complete&pasarela=payphone`,
          cancellationUrl: `${process.env.NEXT_PUBLIC_RESPONSE_URL}/?deposit=cancel&pasarela=payphone`,
        };

        setIsLoading(true);
        const res = await getPayPhoneCallback(json);

        if (res) {
          setIsLoading(false);
          window.location.href = res.payWithCard;
        } else {
          setIsLoading(false);
          toast({
            title: 'Error',
            description: 'Error iniciando pago',
            variant: 'destructive',
          });
        }
      }
    } catch (Depositerror: any) {
      setIsLoading(false);
      toast({
        title: 'Error',
        description: `Error iniciando pago: ${Depositerror.message}`,
        variant: 'destructive',
      });
    }
  };

  const handleAmountChange = (event: { target: { value: any } }) => {
    const { value } = event.target;
    // This regex ensures only numbers and a single dot are entered
    if (/^\d*\.?\d*$/.test(value)) {
      const numericValue = parseFloat(value);
      if (numericValue < minDeposit) {
        setError('El monto mínimo de depósito es L 25');
      } else if (numericValue > maxDeposit) {
        setError('El monto máximo de depósito es L 12.500');
      } else {
        setError('');
      }
      setSelectedAmount(value);
    }
  };

  const formatFinalAmount = () => {
    if (selectedAmount !== '') {
      setSelectedAmount(parseFloat(selectedAmount).toFixed(2));
    }
  };

  return (
    <div>
      <p className="text-xs text-sivarbet-text ">Acreditación inmediata</p>
      <Separator className="my-2 bg-sivarbet-border" />
      <h3 className="text-lg font-semibold text-center">Completa tu deposito</h3>

      <Separator className="my-2 bg-sivarbet-border" />
      <div onClick={togglePromoCode} className="cursor-pointer flex justify-between">
        <span className="promo text-sivarbet-text">Código promocional</span>
        <Link href="/" className="flex gap-1 items-center text-sivarbet-primary">
          <span>
            {showPromoInput ? <CiCircleMinus /> : <CiCirclePlus />}
          </span>
          <span>Ingresa el código</span>
        </Link>
      </div>
      {showPromoInput && (
        <div className="my-4">
          <Input
            type="text"
            value={promoCode}
            onChange={(e) => { return setPromoCode(e.target.value); }}
            placeholder="Codigo"
            className="input-form-text"
          />
        </div>
      )}
      <ul className="flex items-stretch justify-between gap-4 my-4 w-full">
        {[250, 500, 1000].map((itm) => {
          return (
            <li
              key={itm}
              onClick={() => { return handleAmountClick(itm); }}
              className="cursor-pointer p-2 border-none bg-sivarbet-primary hover:bg-sivarbet-primary-hovers rounded w-full"
            >
              <h5 className="text-center">
                L
                {itm}
              </h5>
            </li>
          );
        })}
        <li onClick={handleOtherValueClick} className="cursor-pointer p-2 border-none bg-sivarbet-primary hover:bg-sivarbet-primary-hover rounded text-nowrap">
          <Link href="/">Otro valor</Link>
        </li>
      </ul>
      {isLoading && <LoadingSpinner />}
      <form onSubmit={handleDeposit}>
        <div className="mb-4">
          <Input
            type="number"
            id="dAmount"
            placeholder="Monto de deposito"
            value={selectedAmount}
            onChange={handleAmountChange}
            onBlur={formatFinalAmount}
            disabled={!isOtherValue}
            min="24"
            step="0.01"
            className="input-form-text"
          />
          {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>
        <Separator className="my-2 bg-sivarbet-border" />
        <div className="my-3">
          <div className="items-center flex justify-between">
            <span>Total</span>
            <span>
              L
              {' '}
              {selectedAmount ? parseFloat(selectedAmount).toFixed(2) : '0'}
            </span>
          </div>
          {selectedAmount ? (
            <div className="items-center flex justify-between text-sivarbet-secondary">
              <IconGift width={20} height={18} className="ms-2" />
              <span>
                + L
                {' '}
                {selectedAmount ? (parseFloat(selectedAmount) * 0.05).toFixed(2) : '0'}
              </span>
            </div>
          ) : null}
        </div>
        <Separator className="my-2 bg-sivarbet-border" />
        <div className="btn-area">
          <Button type="submit" disabled={!isVerify || selectedAmount === '' || error !== ''} className="w-full p-2 bg-sivarbet-primary hover:bg-sivarbet-primary-hover text-white rounded">
            <span>Completar Deposito</span>
          </Button>
        </div>
        <p className="text-center text-sm mt-4 text-white">
          Serás redirigido al sitio de PayPhone para completar tu depósito.
        </p>
      </form>
    </div>
  );
};

export default CreditCardOption;
