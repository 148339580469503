'use client';

import React, { useState, useEffect } from 'react';

import Image from 'next/image';

import { signIn } from 'next-auth/react';

import { FcGoogle } from 'react-icons/fc';
import { z } from 'zod';

import { AuthType } from '@/enums/core';
import { FormEmailAndPassword, FormPhoneCodeAndPassword, FormPhoneNumber } from '@/features/auth/components';
import WebView from '@/features/auth/components/WebView';
import { FirebaseStatus } from '@/features/auth/enums';
import { messages } from '@/features/auth/localization';

import { handleSendPhoneCode } from '@/features/auth/services';
import { core, errors } from '@/localization';

import { userUIStore } from '@/stores/userUIStore';

import { resendEmailVerification } from '@/utils/core';
import { handleRegisterWithEmailAndPassword, handleRegisterWithGoogle } from '@/utils/firebaseOptions';

import { FAKE_EMAIL } from '@/constants/core';

import { useToast } from '@/components/ui/use-toast';

import { Button } from '../ui/button';
import {
  Dialog, DialogContent, DialogTitle, DialogTrigger,
} from '../ui/dialog';

interface Props {
  isWebView:boolean,
  isDefaultOpen: boolean
}

const RegisterModal = ({ isDefaultOpen, isWebView }: Props) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileForm, setIsMobileForm] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [opt, setOpt] = useState<any>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Consider mobile if width <= 768px
    };

    handleResize(); // Initialize on first render
    window.addEventListener('resize', handleResize);

    // Cleanup event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const {
    isRegisterModalOpen, toggleRegisterModal, toggleLoginModal, isCheckboxChecked,
  } = userUIStore();

  const { toast } = useToast();

  // REGISTER
  const handleSendCodeToPhone = async (values: z.infer<any>):Promise<void> => {
    try {
      setLoading(true);
      const currentPhoneNumber = values.phone.includes('+') ? values.phone : `+${values.phone}`;
      setPhoneNumber(currentPhoneNumber);

      const result = await handleSendPhoneCode(currentPhoneNumber);
      if (result && typeof result === 'object' && 'verificationId' in result) setOpt(result);
      else if (result && typeof result === 'object' && 'message' in result && 'className' in result) toast({ title: result.message, className: result.className });
      else toast({ title: errors.tryAgain, variant: 'destructive' });
    } catch (error) {
      setLoading(false);
      toast({ title: errors.tryAgain, variant: 'destructive' });
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleRegister = async ():Promise<void> => {
    try {
      if (!isCheckboxChecked) {
        toast({ title: messages.errorCheckbox, variant: 'destructive' });
        return;
      }
      setLoading(true);
      const credential = await handleRegisterWithGoogle();

      if (credential && typeof credential !== 'string' && credential.user) {
        const displayName = credential.user?.displayName?.split(' ');

        const options = {
          callbackUrl: '/dashboard?reg=1',
          email: credential.user?.email,
          password: credential.user?.uid,
          image: credential.user?.photoURL ?? '',
          lastName: displayName?.[1] ?? '',
          name: displayName?.[0] ?? '',
          parent: 0,
          type: AuthType.REGISTER,
          vendor: 1,
        };

        await signIn('credentials', options);
      } else if (typeof credential === 'string' && credential === FirebaseStatus.AUTH_POPUP_CLOSED_BY_USER) {
        setLoading(false);
        toast({ title: messages.errorPopupClosedByUser, variant: 'destructive' });
      } else {
        setLoading(false);
        toast({ title: errors.tryAgain, variant: 'destructive' });
      }
    } catch (error) {
      setLoading(false);
      toast({ title: errors.tryAgain, variant: 'destructive' });
    }
  };

  const handleCheckPhoneCodeAndRegisterWithPhone = async (values: z.infer<any>):Promise<void> => {
    try {
      setLoading(true);

      const phoneCredential = await opt?.confirm(values.opt);
      if (phoneCredential) {
        const email = `${phoneNumber.replace(/\D/g, '')}${FAKE_EMAIL}`;
        const credential = await handleRegisterWithEmailAndPassword(email, values.password);
        if (credential && typeof credential !== 'string' && credential.user) {
          const options = {
            email,
            password: credential.user.uid,
            callbackUrl: '/dashboard',
            type: AuthType.REGISTER,
            vendor: 3,
          };
          await signIn('credentials', options);
        } else if (credential && typeof credential === 'string' && credential === FirebaseStatus.AUTH_EMAIL_EXISTS) {
          setLoading(false);
          toast({ title: messages.errorUserRegistered, variant: 'destructive' });
        }
      } else {
        setLoading(false);
        toast({ title: messages.errorVerifyPhoneCode, variant: 'destructive' });
      }
    } catch (error) {
      setLoading(false);
      toast({ title: errors.tryAgain, variant: 'destructive' });
    }
  };

  const handleEmailAndPassRegister = async (values: z.infer<any>):Promise<void> => {
    try {
      setLoading(true);
      if (values.email && values.password && values.ageVerification) {
        const credential = await handleRegisterWithEmailAndPassword(values.email, values.password);
        if (credential && typeof credential !== 'string' && credential.user) {
          const options = {
            email: credential.user?.email,
            password: credential.user?.uid,
            callbackUrl: '/dashboard?reg=1',
            type: AuthType.REGISTER,
            vendor: 2,
          };
          await signIn('credentials', options);

          const isEmailSent = await resendEmailVerification();
          if (isEmailSent) toast({ title: messages.emailSent, className: 'bg-sivarbet-primary text-sivarbet-background' });
        } else if (credential && typeof credential === 'string' && credential === FirebaseStatus.AUTH_EMAIL_EXISTS) {
          setLoading(false);
          toast({ title: messages.errorUserRegistered, variant: 'destructive' });
        } else {
          setLoading(false);
          toast({ title: errors.tryAgain, variant: 'destructive' });
        }
      } else {
        setLoading(false);
        toast({ title: errors.tryAgain, variant: 'destructive' });
      }
    } catch (error) {
      setLoading(false);
      toast({ title: errors.tryAgain, variant: 'destructive' });
    }
  };

  // END, REGISTER

  return (
    <Dialog modal={false} defaultOpen={isDefaultOpen} open={isRegisterModalOpen} onOpenChange={toggleRegisterModal}>
      <DialogTrigger asChild>
        <Button className="bg-sivarbet-primary text-sivarbet-background font-semibold" size="sm" variant="secondary">
          {messages.register}
        </Button>
      </DialogTrigger>
      <DialogContent
        onOpenAutoFocus={(e) => { return e.preventDefault(); }}
        onInteractOutside={(e) => { e.preventDefault(); }}
        className="bg-sivarbet-secondary-bg border-none p-0 flex flex-col lg:flex-row lg:flex-nowrap lg:max-w-[816px] scroll-my-1 lg:max-h-[630px] h-full rounded-lg"
      >
        <div className={`relative ${isMobile ? 'w-full h-[200px]' : 'w-1/2 h-auto'}`}>
          <Image
            src={isMobile ? '/img/modal/registermobile.jpg' : '/img/modal/register.jpg'}
            alt="register"
            fill
            objectFit="cover"
            objectPosition="center"
            sizes="(max-width: 768px) 100vw, 50vw"
            priority
          />
        </div>
        <section className="lg:w-[50%] flex flex-col justify-center h-full pb-5 lg:max-h-[630px] lg:pt-12 lg:overflow-y-auto">
          {isWebView ? (<WebView />) : (
            <>
              <DialogTitle className="flex items-center justify-start relative text-inherit leading-[20px] font-medium font-inherit mx-[17px] mb-8">
                {`${messages.registerTo} ${core.appName}`}
              </DialogTitle>
              <div className="h-[11px] relative tracking-[-0.01em] leading-[20px] inline-block mx-[17px] max-lg:hidden mb-6">
                <span className="mr-2">{messages.alreadyHaveAnAccount}</span>
                <span
                  onClick={() => {
                    toggleRegisterModal();
                    toggleLoginModal();
                  }}
                  className="[text-decoration:underline] text-sivarbet-primary cursor-pointer"
                >
                  {messages.enterHere}
                </span>
              </div>

              {isMobileForm && !opt && <FormPhoneNumber action={handleSendCodeToPhone} disabled={loading} />}
              {isMobileForm && opt && <FormPhoneCodeAndPassword action={handleCheckPhoneCodeAndRegisterWithPhone} disabled={loading} isRegister /> }
              {!isMobileForm && <FormEmailAndPassword action={handleEmailAndPassRegister} disabled={loading} isRegister />}

              <div className="h-[11px] relative tracking-[-0.01em] leading-[20px] inline-block mx-[17px] lg:hidden mb-5">
                <span>{messages.alreadyHaveAnAccount}</span>
                <span
                  onClick={() => {
                    toggleRegisterModal();
                    toggleLoginModal();
                  }}
                  className="[text-decoration:underline] text-sivarbet-primary cursor-pointer"
                >
                  {messages.enterHere}
                </span>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[30px] text-mini text-third_text mx-[17px]">
                <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[10px]">
                  <div className="center-box">
                    <div className="self-stretch h-px relative box-border border-t-[1px] border-solid border-sivarbet-border" />
                  </div>
                  <div className="relative leading-[11px] inline-block min-w-[104px]">
                    {messages.orRegisterWith}
                  </div>
                  <div className="center-box">
                    <div className="self-stretch h-px relative box-border border-t-[1px] border-solid border-sivarbet-border" />
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[10px]">
                  <Button disabled={loading} onClick={() => { setIsMobileForm(!isMobileForm); }} type="button" className="cursor-pointer [border:none] py-[10.5px] px-5 bg-sivarbet-background self-stretch rounded-lg overflow-hidden flex flex-row items-center justify-center gap-[8px]">
                    <span className={`icon icon-${!isMobileForm ? 'phone' : 'mail'} text-sivarbet-primary mt-1 mr-2`} />
                    <div className="w-[145px] flex items-center justify-center pt-[4.5px] px-0 pb-0 box-border">
                      <div className="self-stretch h-2.5 relative text-sm leading-[20px] font-medium font-text-sm-medium text-sivarbet-primary text-center flex items-center justify-center">
                        {`${messages.registerWith} ${isMobileForm ? messages.yourEmail.toLocaleLowerCase() : messages.yourPhone.toLocaleLowerCase()}`}
                      </div>
                    </div>
                  </Button>
                  <Button disabled={loading} onClick={handleGoogleRegister} type="button" className="cursor-pointer [border:none] py-[10.5px] px-5 bg-sivarbet-background self-stretch rounded-lg overflow-hidden flex flex-row items-center justify-center gap-[8px]">
                    {/* <span className="icon icon-google text-sivarbet-primary mt-1" /> */}
                    <FcGoogle className="text-lg" />

                    <div className="w-[145px] flex items-center justify-center pt-[4.5px] px-0 pb-0 box-border">
                      <div className="self-stretch h-2.5 relative text-sm leading-[20px] font-medium font-text-sm-medium text-sivarbet-primary text-center flex items-center justify-center">
                        {`${messages.registerWith} ${messages.google}`}
                      </div>
                    </div>
                  </Button>
                </div>
              </div>
            </>
          )}
        </section>
      </DialogContent>
    </Dialog>
  );
};

export default RegisterModal;
