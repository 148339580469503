import { NextResponse } from 'next/server';

import { findUserInAltenar } from '@/features/deposit/utils';

import { exception } from '@/utils/core';

export async function fetchCoinRates() {
  try {
    const response = await fetch('/api/deposit/coinpayments/getRates', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      return NextResponse.json({ error: 'Error with the network please try again later' }, { status: 500 });
    }

    return await response.json();
  } catch (error) {
    return NextResponse.json({ error: 'Error with the network please try again later' }, { status: 500 });
  }
}

export async function createCallbackAddress(data: any) {
  try {
    const response = await fetch('/api/deposit/coinpayments/getCallbackAddress', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Error with the network please try again later');
    }

    return await response.json();
  } catch (error) {
    await exception(error, { route: '/features/deposit/services/index.ts: createCallbackAddress', method: 'POST', req: data });
    return NextResponse.json({ error: 'Error with the network please try again later' }, { status: 500 });
  }
}

export async function getNicoCallback(data: any) {
  try {
    const response = await fetch('/api/deposit/nico', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    return await response.json();
  } catch (error) {
    await exception(error, { route: '/features/deposit/services/index.ts: getNicoCallback', method: 'POST', req: data });
    return NextResponse.json({ error: 'Error with the network please try again later' }, { status: 500 });
  }
}

export async function getPayPhoneCallback(data: any) {
  try {
    const response = await fetch('/api/deposit/payphone', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const { response: res } = await response.json();
    return res;
  } catch (error) {
    await exception(error, { route: '/features/deposit/services/index.ts: getPayPhoneCallback', method: 'POST', req: data });
    return NextResponse.json({ error: 'Error with the network please try again later' }, { status: 500 });
  }
}

export async function getDirectaCallback(data: any) {
  try {
    const response = await fetch('/api/deposit/directa', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    const { response: res } = await response.json();
    return res;
  } catch (error) {
    await exception(error, { route: '/features/deposit/services/index.ts: getDirectaCallback', method: 'POST', req: data });
    return NextResponse.json({ error: 'Error with the network please try again later' }, { status: 500 });
  }
}

export async function fetchConfirmationPayphone(data: any) {
  try {
    const response = await fetch('/api/deposit/webhook/payphone', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Error with the network please try again later');
    }

    return await response.json();
  } catch (error) {
    await exception(error, { route: '/features/deposit/services/index.ts: fetchConfirmationPayphone', method: 'POST', req: data });
    return NextResponse.json({ error: 'Error with the network please try again later' }, { status: 500 });
  }
}
/* eslint-disable no-console */
export async function fetchCreateBonus(userId: number, deposit: number) {
  try {
    // ================== Create Bonus promisse ==================
    const response = await fetch(`${process.env.NEXT_PUBLIC_RESPONSE_URL}/api/integrations/bonusService`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, deposit }),
    });

    console.log('response========> Bonus', response);
    console.log('response.ok========> Bonus', response.ok);

    if (!response.ok) {
      return false;
    }

    return true;
  } catch (error) {
    console.error('Error al crear el bono:', error);
    return NextResponse.json({ error: 'External request failed try again later' }, { status: 500 });
  }
}

export async function fetchCreateUserBonusByDeposit(data:any) {
  try {
    // const isRegistrer = await findUserInAltenar(data.id_user);
    // if (!isRegistrer) {
    //   const
    // }
    const responseData = await findUserInAltenar(data.id_user);
    return responseData;
  } catch (error) {
    return NextResponse.json({ error: 'External request failed try again later' }, { status: 500 });
  }
}
