/* eslint-disable max-lines-per-function */
import React from 'react';

import Link from 'next/link';

import {
  getBettingrules,
  getDepositsWithdrawals,
  getFrequentQuestions,
  getDisputeResolutionPost,
  getKycpolicies,
  getMoneyLaundering,
  getResponsibility,
  getBettinglimit,
  getCategory,
  getSanityPosts,
  getSanityPrivacyPolicy,
} from '@/sanity/sanity-utils';

import ReusableModal from '@/components/modals/ReusableModal';
import { Dialog, DialogTrigger } from '@/components/ui/dialog';

import SocialLinks from './SocialLinks';

const FooterLinks = () => {
  return (
    <div className="flex flex-row max-lg:flex-wrap justify-between gap-1">
      <div className="mb-8 lg:mb-0">
        <h4 className="info-header">Información General</h4>
        <ul className="list-secondary-gap-2">
          <li>
            <Dialog modal>
              <DialogTrigger asChild className="cursor-pointer">
                <span>Reglas de apuestas</span>
              </DialogTrigger>
              <ReusableModal
                title="Reglas de apuestas"
                getData={getBettingrules}
                renderPortableText={(post) => {
                  return (
                    <div key={post.length}>
                      {post.map((item: any) => {
                        return (
                          <div key={item.id}>
                            <p key={item.id}>
                              {item.text}
                            </p>
                            <br />
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
                app="hondubet"
              />
            </Dialog>
          </li>
          <li>
            <Dialog modal>
              <DialogTrigger asChild className="cursor-pointer">
                <span>Depositos</span>
              </DialogTrigger>
              <ReusableModal
                title="Depositos"
                getData={getDepositsWithdrawals}
                renderPortableText={(post) => {
                  return (
                    <div key={post.length}>
                      {post.map((item: any) => {
                        return (
                          <div key={item.id}>
                            <p key={item.id}>
                              {item.text}
                            </p>
                            <br />
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
                app="hondubet"
              />
            </Dialog>
          </li>
          <li>
            <Dialog modal>
              <DialogTrigger asChild className="cursor-pointer">
                <span>Retiros</span>
              </DialogTrigger>
              <ReusableModal
                title="Depositos"
                getData={getDepositsWithdrawals}
                renderPortableText={(post) => {
                  return (
                    <div key={post.length}>
                      {post.map((item: any) => {
                        return (
                          <div key={item.id}>
                            <p key={item.id}>
                              {item.text}
                            </p>
                            <br />
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
                app="hondubet"
              />
            </Dialog>
          </li>
          <li>
            <Dialog modal>
              <DialogTrigger asChild className="cursor-pointer">
                <span>Limite de Apuestas</span>
              </DialogTrigger>
              <ReusableModal
                title="Limite de Apuestas"
                getData={getBettinglimit}
                renderPortableText={(post) => {
                  return (
                    <div key={post.length}>
                      {post.map((item: any) => {
                        return (
                          <div key={item.id}>
                            <p key={item.id}>
                              {item.text}
                            </p>
                            <br />
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
                app="hondubet"
              />
            </Dialog>
          </li>
        </ul>
      </div>
      <div className="mb-8 lg:mb-0">
        <h4 className="info-header">Nuestras Secciones</h4>
        <ul className="list-secondary-gap-2">
          <li>
            <Link href="/">Inicio</Link>
          </li>
          <li>
            <Link href="/casino">Casino</Link>
          </li>
          <li>
            <Link href="/deportes">Deportes</Link>
          </li>
          <li>
            <Link href="/dashboard">Perfil</Link>
          </li>
          <li>
            <Link href="/dashboard/withdrawals">Retiros</Link>
          </li>
        </ul>
      </div>
      <div className="mb-8 lg:mb-0">
        <h4 className="info-header">Seguridad & Privacidad</h4>
        <ul className="list-secondary-gap-2">
          <li>
            <Dialog modal>
              <DialogTrigger asChild className="cursor-pointer">
                <span>Politica de Privacidad </span>
              </DialogTrigger>
              <ReusableModal
                title="Politica de Privacidad"
                getData={getSanityPrivacyPolicy}
                renderPortableText={(post) => {
                  return (
                    <div key={post.length}>
                      {post.map((item: any) => {
                        return (
                          <div key={item.id}>
                            <p key={item.id}>
                              {item.text}
                            </p>
                            <br />
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
                app="hondubet"
              />
            </Dialog>
          </li>
          <li>
            <Dialog modal>
              <DialogTrigger asChild className="cursor-pointer">
                <span>Politica de Cookies</span>
              </DialogTrigger>
              <ReusableModal
                title="Politica de Cookies"
                getData={getSanityPosts}
                renderPortableText={(post) => {
                  return (
                    <div key={post.length}>
                      {post.map((item: any) => {
                        return (
                          <div key={item.id}>
                            <p key={item.id}>
                              {item.text}
                            </p>
                            <br />
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
                app="hondubet"
              />
            </Dialog>
          </li>
          <li>
            <Dialog modal>
              <DialogTrigger asChild className="cursor-pointer">
                <span>Condiciones de Bonos</span>
              </DialogTrigger>
              <ReusableModal
                title="Condiciones de Bonos"
                getData={getCategory}
                renderPortableText={(post) => {
                  return (
                    <div key={post.length}>
                      {post.map((item: any) => {
                        return (
                          <div key={item.id}>
                            <p key={item.id}>
                              {item.text}
                            </p>
                            <br />
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
                app="hondubet"
              />
            </Dialog>
          </li>
          <li>
            <Dialog modal>
              <DialogTrigger asChild className="cursor-pointer">
                <span>Juego Responsable</span>
              </DialogTrigger>
              <ReusableModal
                title="Juego Responsable"
                getData={getResponsibility}
                renderPortableText={(post) => {
                  return (
                    <div key={post.length}>
                      {post.map((item: any) => {
                        return (
                          <div key={item.id}>
                            <p key={item.id}>
                              {item.text}
                            </p>
                            <br />
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
                app="hondubet"
              />
            </Dialog>
          </li>
          <li>
            <Dialog modal>
              <DialogTrigger asChild className="cursor-pointer">
                <span>Políticas KYC</span>
              </DialogTrigger>
              <ReusableModal
                title="Políticas KYC"
                getData={getKycpolicies}
                renderPortableText={(post) => {
                  return (
                    <div key={post.length}>
                      {post.map((item: any) => {
                        return (
                          <div key={item.id}>
                            <p key={item.id}>
                              {item.text}
                            </p>
                            <br />
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
                app="hondubet"
              />
            </Dialog>
          </li>
          <li>
            <Dialog modal>
              <DialogTrigger asChild className="cursor-pointer">
                <span>Anti-Lavado de Dinero</span>
              </DialogTrigger>
              <ReusableModal
                title="Lavado de Dinero"
                getData={getMoneyLaundering}
                renderPortableText={(post) => {
                  return (
                    <div key={post.length}>
                      {post.map((item: any) => {
                        return (
                          <div key={item.id}>
                            <p key={item.id}>
                              {item.text}
                            </p>
                            <br />
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
                app="hondubet"
              />
            </Dialog>
          </li>
        </ul>
      </div>
      <div className="mb-8 lg:mb-0">
        <h4 className="info-header">Necesitas ayuda?</h4>
        <ul className="list-secondary-gap-2">
          <li>
            <Dialog modal>
              <DialogTrigger asChild className="cursor-pointer">
                <span>Preguntas Frecuentes</span>
              </DialogTrigger>
              <ReusableModal
                title="Preguntas Frecuentes"
                getData={getFrequentQuestions}
                renderPortableText={(post) => {
                  return (
                    <div key={post.length}>
                      {post.map((item: any) => {
                        return (
                          <div key={item.id}>
                            <p key={item.id}>
                              {item.text}
                            </p>
                            <br />
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
                app="hondubet"
              />

            </Dialog>
          </li>
          <li>
            <Link href="https://api.whatsapp.com/send?phone=50492062735&text=%C2%A1Hola">
              Contactanos
            </Link>
          </li>
          <li>
            <Dialog modal>
              <DialogTrigger asChild className="cursor-pointer">
                <span>Resolución de Disputas</span>
              </DialogTrigger>
              <ReusableModal
                title="Resolución de Disputas"
                getData={getDisputeResolutionPost}
                renderPortableText={(post) => {
                  return (
                    <div key={post.length}>
                      {post.map((item: any) => {
                        return (
                          <div key={item.id}>
                            <p key={item.id}>
                              {item.text}
                            </p>
                            <br />
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
                app="hondubet"
              />
            </Dialog>
          </li>
          <li>
            <Link href="/nuevo-agente">
              ¿Quieres ser Agente Hondubet?
            </Link>
          </li>
        </ul>
      </div>
      <SocialLinks />
    </div>
  );
};

export default FooterLinks;
