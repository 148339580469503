/** @format */

'use client';

import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/navigation';
import Script from 'next/script';

import { BiJoystick } from 'react-icons/bi';
import { CiGift } from 'react-icons/ci';
import { GiMoneyStack } from 'react-icons/gi';

import { IoHomeOutline, IoPlayCircleOutline } from 'react-icons/io5';

import { MdOutlineSportsVolleyball } from 'react-icons/md';

import CasinoTab from '@/features/sidebar/CasinoTab';
import SportsTab from '@/features/sidebar/SportTab';
import SupportButton from '@/features/sidebar/SupportButton';
import { userUIStore } from '@/stores/userUIStore';

import { OptionsSidebar } from '@/interfaces/core';

import {
  Tabs, TabsList, TabsTrigger,
} from '@/components/ui/tabs';

import LinkItem from '../items/LinkItem';
import { ScrollArea } from '../ui/scroll-area';

const SideNavbar = () => {
  const { isSidebarOpen, toggleSidebar } = userUIStore();
  const router = useRouter();
  const [currentPath, setCurrentPath] = useState('');
  const arrOptions: OptionsSidebar[] = [
    {
      title: 'Baloncesto',
      icon: 'basketball',
      type: 'sport',
      href: '/deportes?leagueLink=championship/2980',
    },
    {
      title: 'Hokey',
      icon: 'hockey',
      type: 'sport',
      href: '/deportes?leagueLink=championship/3232',
    },
    {
      title: 'Tenis',
      icon: 'tennil',
      type: 'sport',
      href: '/deportes?leagueLink=championship/3158',
    },
    {
      title: 'Voleibol',
      icon: 'voleibol',
      type: 'sport',
      href: '/deportes?leagueLink=championship/20970',
    },
    {
      title: 'Arcade',
      href: '/casino/?arcade=casual',
      icon: 'joystick',
      type: 'casino',
    },
    {
      title: 'Slots',
      href: '/casino/?slot=slots',
      icon: 'slot',
      type: 'casino',
    },
    {
      title: 'Loteria',
      href: '/casino/?lottery=lottery',
      icon: <GiMoneyStack className="text-2xl self-center" />,
      type: 'casino',
    },
    {
      title: 'Juegos de Cartas',
      href: '/casino/?card=card',
      icon: 'cards',
      type: 'casino',
    },
    {
      title: 'Juegos Crash',
      href: '/casino/?crash=crash',
      icon: 'dart',
      type: 'casino',
    },
  ];
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCurrentPath(window.location.pathname);
    }
  }, [currentPath, toggleSidebar]);

  const isOpenSide = (isSidebarOpen) ? 'w-[80px]' : 'w-72';

  return (
    <>
      <Script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=1164835f-2b22-42cb-9d5c-197511b1d9f5"
        strategy="afterInteractive"
        onLoad={() => {
          if (typeof window.zE !== 'undefined') {
            window.zE('webWidget', 'hide');
          }
        }}
      />
      <div data-testid="sidebar" className={`max-md:hidden fixed z-10 px-3 h-full bg-sivarbet-secondary-bg text-sivarbet-text transition-all duration-500 ${isOpenSide}`}>
        <div data-collapsed={isSidebarOpen} className="group flex items-center flex-col gap-4 py-2 text-sivarbet-text h-full w-full">
          <ScrollArea className="h-full">
            <nav className="grid gap-1 px-2 h-full">
              <Tabs value={currentPath.includes('/casino') || currentPath.includes('/favoritos') ? 'casino' : 'sport'} className="h-full">
                <TabsList className={`w-full ${isSidebarOpen ? 'flex flex-col items-center mt-20 h-[80px]' : 'grid grid-cols-2 mt-20 pb-12'} bg-sivarbet-background rounded-xl text-sivarbet-text`}>
                  <TabsTrigger
                    value="sport"
                    className="flex-center-active data-[state=active]:bg-sivarbet-primary"
                    onClick={() => { return router.push('/deportes#/overview'); }}
                  >
                    <span className="mx-1 fill-white icon icon-ball" />
                    {!isSidebarOpen && <span>Deportes</span>}
                  </TabsTrigger>
                  <TabsTrigger
                    value="casino"
                    className="flex-center-active data-[state=active]:bg-sivarbet-primary"
                    onClick={() => { return router.push('/casino'); }}
                  >
                    <span className="mx-1 fill-white icon icon-cards" />
                    {!isSidebarOpen && <span>Casino</span>}
                  </TabsTrigger>
                </TabsList>
                <div
                  className={`flex flex-col bg-sivarbet-background my-5 rounded-xl ${isSidebarOpen && 'items-center'} `}
                >
                  <LinkItem
                    link={{
                      title: 'Inicio',
                      href: '/',
                      icon: <IoHomeOutline className={`${!isSidebarOpen && 'mr-2'} self-center h-4 w-4 text-xl`} />,
                      type: 'general',
                    }}
                    isCollapsed={isSidebarOpen}
                  />
                  <LinkItem
                    link={{
                      title: 'Eventos en vivo',
                      href: '/deportes#/live',
                      icon: <IoPlayCircleOutline className={`${!isSidebarOpen && 'mr-2'} self-center h-4 w-4 text-xl`} />,
                      type: 'general',
                    }}
                    isCollapsed={isSidebarOpen}
                  />
                  <LinkItem
                    key={2}
                    link={{
                      title: 'Promociones',
                      href: '/promotions',
                      icon: <CiGift className={`${!isSidebarOpen && 'mr-2'} self-center h-4 w-4 text-xl`} />,
                      type: 'general',
                    }}
                    isCollapsed={isSidebarOpen}
                  />
                </div>
                <SportsTab isOpen={!isSidebarOpen} arrOptions={arrOptions} />
                <CasinoTab isOpen={!isSidebarOpen} arrOptions={arrOptions} />
                <div
                  className={`flex flex-col bg-sivarbet-background my-5 rounded-xl ${isSidebarOpen && 'items-center'}`}
                >
                  <LinkItem
                    link={{
                      title: 'Esports',
                      href: '/deportes#/sport/146',
                      icon: <BiJoystick className="self-center h-4 w-4 text-xl" />,
                      type: 'general',
                    }}
                    isCollapsed={isSidebarOpen}
                  />
                </div>
                <div
                  className={`flex flex-col bg-sivarbet-background my-5 rounded-xl ${isSidebarOpen && 'items-center'}`}
                >
                  <LinkItem
                    link={{
                      title: 'Todos los juegos',
                      href: '/deportes#/overview',
                      icon: <MdOutlineSportsVolleyball className="self-center h-4 w-4 text-xl" />,
                      type: 'general',
                    }}
                    isCollapsed={isSidebarOpen}
                  />
                </div>
                <div
                  className={`flex flex-col bg-sivarbet-background my-5 rounded-xl ${isSidebarOpen && 'items-center'}`}
                >
                  <LinkItem
                    key={2}
                    link={{
                      title: 'Favoritos',
                      href: '/favoritos',
                      icon: 'star',
                      type: 'general',
                    }}
                    isCollapsed={isSidebarOpen}
                  />
                </div>
                <SupportButton isOpen={!isSidebarOpen} />
              </Tabs>
            </nav>
          </ScrollArea>
        </div>
      </div>
    </>
  );
};

export default SideNavbar;
