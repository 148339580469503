import React, { useEffect, useReducer, useState } from 'react';

import Image from 'next/image';

import SmallPopup from '@/features/Bonus/SmallPopup';
import { Confetti } from '@/features/freespins/components';

import Body from '@/public/img/profile/Body.png';
import top from '@/public/img/profile/top-gift.png';

type StateType = Record<string, string | undefined>;
type ActionType = Partial<StateType>;

const initState: StateType = {
  move: 'move',
  jump: '',
  rotated: '',
  rotating: '',
};
const GiftBoxAnimation = React.memo(({ amount }: { amount: number }) => {
  const reducer = (state: StateType, newState: ActionType): StateType => {
    return {
      ...state,
      ...newState,
    };
  };
  const [state, setState] = useReducer(reducer, initState);
  const [showCoin, setShowCoin] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // const [data, setData] = useState([]);
  const {
    move,
    rotating,
    rotated,
    jump,
  } = state;
  // useEffect(() => {
  //   const checkSession = async () => {
  //     const response = await fetch('/api/integrations/casino/freespins', {
  //       method: 'GET',
  //     });
  //     if (response.ok) {
  //       const resp = await response.json();
  //       setData(resp.data);
  //     }
  //   };
  //   checkSession();
  // }, []);

  useEffect(() => {
    const startAnimation = () => {
      setState({ rotating: 'rotating', jump: 'jump', rotated: 'rotated' });
      setShowCoin(true);
    };

    const resetAnimation = () => {
      setState(initState);
      setShowCoin(false);
    };
    const startTimer = setTimeout(() => {
      startAnimation();

      const resetTimer = setTimeout(() => {
        resetAnimation();
      }, 2000);

      return () => {
        clearTimeout(resetTimer);
      };
    }, 2000);

    return () => {
      clearTimeout(startTimer);
    };
  }, []);

  // if (!data?.length) {
  //   return null;
  // }
  return (
    <>
      {/* <FreeSpindModal isOpen={showModal} onClose={() => { return setShowModal(!showModal); }} data={data} /> */}
      <SmallPopup isVisible={showModal} setIsVisible={setShowModal} amount={amount} />
      <div className="box sm-x-2 mx-3" onClick={() => { return setShowModal(true); }}>
        <Confetti open={jump === 'jump'} />
        {showCoin && (
          <span id="coin" className={`kuku ${jump}`} />
        )}
        <Image src={top} alt="box" className={`${move} ${rotating} ${rotated} GiftTop `} />
        <Image className="GiftBody" src={Body} alt="box-lid" />
      </div>
    </>
  );
});

export default GiftBoxAnimation;
